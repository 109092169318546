<template>
  <div class="image-drawer-container">
    <DrawerResult class="drawer-result"/>
    <DrawerOperation class="drawer-container" @createImage="createImage"/>
  </div>
</template>

<script>
export default ({
  components: {
    DrawerOperation: () => import('@/views/application/drawer/component/DrawerOperation.vue'),
    DrawerResult: () => import('@/views/application/drawer/component/DrawerResult.vue')
  },
  data() {
    return {}
  },
  methods: {
    createImage(config) {
      console.log(44444, config)
    }
  }

})

</script>

<style scoped lang="scss">
.image-drawer-container {
  display: flex;
  height: 100%;

  .drawer-container {
    //width: 320px;
    flex: 0 0 320px;
    background: #FFF;
  }

  .drawer-result {
    width: 100%;
    background: #f0f2f5;

  }
}
</style>